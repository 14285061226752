/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// Libraries
import { size as _size, map as _map, find as _find } from 'lodash'

// Components
import { BannerAlt } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

import 'styles/templates/ContactTemplate.scss'
import { Helmet } from 'react-helmet'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        title: banner_title
        image: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      description: content
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
    locations: allWordpressWpLocaties {
      edges {
        node {
          title
          acf {
            streetname
            housenumber
            zipcode
            city
            phonenumber
            emailAddress: email_address
            hq
          }
        }
      }
    }
    socials: allWordpressWpSocials {
      edges {
        node {
          acf {
            location
            icon {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 30) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const ContactTemplate = ({
  data: {
    page: {
      path,
      acf: { title, image },
      description,
      yoastMeta,
    },
    locations: { edges: locations },
    socials: { edges: socials },
  },
}) => {
  const { node: hqLocation } = _find(
    locations,
    ({
      node: {
        acf: { hq },
      },
    }) => hq
  )

  const widgetRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const widgetVisibleDiv = document.querySelector('.widget-visible');

          if (widgetVisibleDiv) {
            const iframeElement = widgetVisibleDiv.nextElementSibling;

            if (iframeElement) {
              if (widgetRef.current && iframeElement) {
                widgetRef.current.appendChild(iframeElement);
              }

              // Stop observing once the element is found
              observer.disconnect();
            }
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, []);


  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={image.localFile.childImageSharp.fixed.src} article />
      <div className="contact-template">
        <BannerAlt>
          <BackgroundImage
            className="banner-background-image"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row pt-5">
            <div className="col-lg-6 pt-5 text-center text-lg-left">
              <h1 className="mt-5">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
            <div className="col-lg-6" />
          </div>
        </BannerAlt>

        <div className="container mt-5 d-block d-lg-none font-family-secondary text-justify">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>

        <div className="page-container container">
          <div className="row mt-lg-0">
            <div className="col-lg-7 pb-5 pr-lg-5">
              {/* <GravityForm id={1} /> */}
              <iframe title="Contactformulier" style={{ width: '100%', height: '100%', minHeight: '1000px' }} src="https://meeting.teamleader.eu/embed/form/de-kerstmarktspecialist/contactformulier" frameBorder="0" />
            </div>
            <div className="contact-template-right col-lg-5 px-lg-5 py-5">
              <h4>Contact</h4>
              <div
                className="page-content font-size-sm mb-4"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <div>
                <Helmet>
                  <script
                    src="https://meeting.teamleader.eu/contact-widget-public/script.js?contactWidgetId=8f3e8d6ee5a943139731cc422f4abd97"
                    async
                  />
                </Helmet>
                <div className="widget-contact-placement" ref={widgetRef} />
              </div>
              <div className="mt-4">
                <h4>
                  Locatie
                  {_size(locations) !== 1 && (<span>s</span>)}
                </h4>
              </div>
              <div className="mt-3">
                {_map(locations, ({ node: { title, acf } }, index) => (
                  <div className="mb-3" key={index}>
                    <div className="font-italic font-weight-l">
                      Locatie
                      {' '}
                      {title}
                    </div>
                    {acf.streetname}
                    {' '}
                    {acf.housenumber}
                    <br />
                    {acf.zipcode}
                    {' '}
                    {acf.city}
                  </div>
                ))}
              </div>
              <div className="mt-5">
                <h4>Contact</h4>
                <div className="mt-3">
                  <a
                    className="d-block"
                    href={`tel:${hqLocation.acf.phonenumber}`}
                  >
                    {hqLocation.acf.phonenumber}
                  </a>
                  <a
                    className="d-block"
                    href={`mailto:${hqLocation.acf.emailAddress}`}
                  >
                    {hqLocation.acf.emailAddress}
                  </a>
                </div>
              </div>
              <div className="mt-5">
                <h4>Social</h4>
                <div className="mt-3">
                  {_map(socials, ({ node: { title, acf } }, index) => (
                    <a
                      className="contact-template-social mr-3"
                      key={index}
                      href={acf.location}
                      title={title}
                    >
                      <Img
                        className="contact-template-social-image"
                        fluid={acf.icon.localFile.childImageSharp.fluid}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactTemplate
